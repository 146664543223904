$theme-colors: (
  "primary": #2F619D,
  "secondary": #e4e4e4,
  "ternary": #089bd9
);

button:disabled,
button[disabled],
.nav.nav-tabs .nav-item,
.disabled {
  cursor: not-allowed !important;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';