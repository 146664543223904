.toolbar {
    margin-bottom: 0.5rem;
}

.toolbar .left-toolbar button {
    margin: 0 0.5rem 0.5rem 0;
}

.toolbar .right-toolbar button {
    margin: 0 0 0.5rem 0.5rem;
}

.custom-dropdown {
    display: inline;
}

.custom-dropdown.dropdown .dropdown-menu {
    @media screen and (max-width: 1023px) {
        max-height: 100px;
    }

    @media screen and (min-width: 1024px) {
        max-height: 250px;
    }
    
    overflow-y: auto;
}

.custom-dropdown.dropdown .dropdown-toggle::after {
    border: none !important;
    content: none !important;
}